import React, { useState} from 'react';
import img from '../utils/images'

const Accordion = ({data, isDark}) => {

    const [step, setStep] = useState(0)

    const activeStepAccordion =(id)=> {
        if (step === id) {
            setStep(0)
        } else {
            setStep(id)
        }
    }

    return (

        <div className={isDark ? 'dark-mode-accordion' : ''}>
            <div className="accordion">
                {
                    data && data.map((item, id)=> {
                        return (
                            <button className='accordion__item' key={id} onClick={()=>activeStepAccordion(item?.id)}>
                                <div className={step === item?.id ? 'accordion__item__icon active' :'accordion__item__icon'}>
                                    {
                                        isDark ?
                                            <img src={img.IconArrowDownWhite} alt="icon" />
                                            :
                                            <img src={img.IconArrowDown} alt="icon" />
                                    }
                                </div>


                                <div className={step === item?.id ? 'accordion__item__title active' :'accordion__item__title'}>
                                    {item?.title_es}
                                </div>

                                {
                                    step === item?.id ?
                                        <div className="accordion__item__contain-info">
                                            <p style={{ whiteSpace: 'pre-line' }} >{item?.paragraph}</p>
                                        </div>
                                        :
                                        undefined
                                }
                            </button>
                        )
                    })
                }

            </div>
        </div>


    );
};


export default Accordion;


