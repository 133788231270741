
import React, {useEffect, useState} from 'react';
import {getBlogDetail, getBlog} from "../modules/actions";
import Header from "../components/header";
import { Link } from "react-router-dom";
import {renderToStaticMarkup} from "react-dom/server";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { useHistory } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'

import { useParams } from "react-router-dom";
import {formatShortDate} from "../utils/helpers";
import Marquee from "../components/marquee";
import PageNotFound from "../components/page-not-found";
import {urls} from "../routes/routes-data";
import images from "../utils/images";
import SEO from "../components/SEO";

const BlogDetail = () => {

    const [info, setInfo] = useState(null)
    const [infoProjects, setInfoProjects] = useState(null)
    const [nexProject, setNexProject] = useState({})
    const [errorMatch, setErrorMatch] = useState(false)

    const params = useParams()
    const history = useHistory()

    const getDetail =async()=>{
        try {
            const response = await getBlogDetail(params)
            setInfo(response?.items[0].fields)
        } catch (error) {
            console.error("error", error)
            setErrorMatch(true)
        }
    }

    const getProjects =async()=>{
        try {
            const response = await getBlog()
            setInfoProjects(response?.items)
        } catch (error) {
            console.error("error", error)
        }
    }

    const findPosition =()=> {
        if (infoProjects && infoProjects.length > 0 && info) {
            let index = infoProjects.findIndex(x => x.fields?.canonUrl === info?.canonUrl)
            let totalProjects = infoProjects.length - 1
            let nexIndexProjects = index + 1
            if (nexIndexProjects > totalProjects) {
                setNexProject(infoProjects[0]?.fields)
            } else {
                setNexProject(infoProjects[nexIndexProjects]?.fields)
            }
        }
    }


    const  document  =  {
        nodeType : 'document' ,
        data : { } ,
        content : [
            {
                nodeType : 'paragraph' ,
                data : { } ,
                content : [
                    {
                        nodeType : 'text' ,
                        value : 'Hello world!' ,
                        marcas : [ ] ,
                        datos : { } ,
                    } ,
                ] ,
            },
        ] ,
    } ;

    const options={
        renderNode:{
            "embedded-asset-block":(node)=>{
                let file = node.data.target.fields.file;
                let jsx = renderMedia(file);
                let markup = renderToStaticMarkup(jsx);
                return markup;
            }
        }
    };


    const renderMedia=(file)=>{
        if(file.contentType==="video/mp4"){
            return (
                <div className='adjust-video'>
                    <video controls>
                        <source src={file.url} type='video/mp4'/>
                        <p>Your browser doesnt support HTML5 video.</p>
                    </video>
                </div>
            );
        }else if(file.contentType==="image/jpeg" || file.contentType==="image/png" || file.contentType==="image/jpg" || file.contentType==="image/gif" || file.contentType==="image/webp"){
            return (<img className="img-full-fluid" src={file.url} alt={file.fileName}/>);
        }
        else{
            return (<p>Unknown content type</p>);
        }
    };

    const nextProjectFunction =(value)=> {
        history.push(value)
        window.location.reload(true);
    }



    useEffect(()=> {
        getDetail()
        getProjects()
        window.scrollTo(0,0)
    }, [])


    useEffect(()=> {
        findPosition()
    }, [infoProjects, info])

    return (
        <div>
            <SEO
                title={`HelloBrand - ${info?.title}`}
                metaDescription="Hellobrand meta description hellobrand, mejora tu marca"
            />
            <Header/>

            {
                errorMatch ?
                    <PageNotFound />
                    :
                    <>
                        <>

                            <div className="blog-detail">

                                <div className="blog-detail__top-info">
                                    <p>{formatShortDate(info?.date)} - {info?.category}</p>
                                    <h1>{info?.title}</h1>
                                </div>


                                <div dangerouslySetInnerHTML={{__html:documentToHtmlString(info?.container, options)}} className="blog-detail__html-cormorant"></div>

                                {
                                    nexProject &&
                                    <div className="blog-detail__next-project" onClick={()=> nextProjectFunction(nexProject?.canonUrl)}>
                                        <p>Siguiente post:</p>
                                        <h3>{nexProject?.title}</h3>

                                    </div>
                                }
                            </div>




                        </>


                    </>

            }


            <div className="blog-detail__content-button">
                <Link to="/team" className="button-xl">
                    Conoce nuestro equipo
                    <FontAwesomeIcon icon={faArrowRight} />
                </Link>
            </div>


            <Marquee
                title="Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works"
                info=">Inspírate con nuestro trabajo"
                link={urls.successStories}
            />

        </div>
    );
};


export default BlogDetail;


