import {createClient} from "contentful";
import { Space, Token } from './env'

const clientTitle = createClient({
    space: Space,
    accessToken: Token
});



export const getSuccessStories = async ()=> {
    return await clientTitle.getEntries({
        content_type: "successStories",
        "order":"-sys.createdAt",
        select: "fields"
    });
}


export const getVideos = async ()=> {
    return await clientTitle.getEntries({
        content_type: "videos",
        "order":"-sys.createdAt",
        select: "fields"
    });
}

export const getSuccessStoriesDetail = async (value)=> {
    const params = value?.id
    return await clientTitle.getEntries({
        content_type: "successStories",
        "fields.canonUrl" : params,
        select: "fields"
    });
}


export const getTeam = async (value)=> {
    return await clientTitle.getEntries({
        content_type: "team",
        "order":"-sys.createdAt",
        select: "fields"
    });
}

export const getBlog = async ()=> {
    return await clientTitle.getEntries({
        content_type: "blog",
        "order":"-fields.date",
        select: "fields"
    });
}

export const getBlogDetail = async (value)=> {
    const params = value?.id
    return await clientTitle.getEntries({
        content_type: "blog",
        "fields.canonUrl" : params,
        select: "fields"
    });
}


/*b81bf309b18043dc3dd7573019476a00b9e88bf48242b0e8ad2429fd6cfdfe432e5af7a4266dcda8aea9e810f7d2b69bf4e67fec509d3ca60a7538aa34e458326e454356dfe18141e67d91435c0697b862bf30136a2a11f48f1ac650068d0ed6d4c548d0462fef635fd259f17e1b3ed7117986cbd4638bcd7a07fe135decc9e4*/

/*export const getCases = async  ()=> {
    try {
        const url = "http://localhost:1337/api/case-stories?populate=*"

        const response = await fetch(url, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "same-origin",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer b81bf309b18043dc3dd7573019476a00b9e88bf48242b0e8ad2429fd6cfdfe432e5af7a4266dcda8aea9e810f7d2b69bf4e67fec509d3ca60a7538aa34e458326e454356dfe18141e67d91435c0697b862bf30136a2a11f48f1ac650068d0ed6d4c548d0462fef635fd259f17e1b3ed7117986cbd4638bcd7a07fe135decc9e4`,
            },
            redirect: "follow",
            referrerPolicy: "no-referrer",
        });
        console.log("soy la respuesta", response)
        return response.json();
    } catch (error) {
        console.error(error)
    }
}*/


/*export const getCasesId = async  (setInfo2)=> {

    const url = "http://localhost:1337/api/case-stories/1?populate=*"
    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",
            'Authorization': `Bearer b81bf309b18043dc3dd7573019476a00b9e88bf48242b0e8ad2429fd6cfdfe432e5af7a4266dcda8aea9e810f7d2b69bf4e67fec509d3ca60a7538aa34e458326e454356dfe18141e67d91435c0697b862bf30136a2a11f48f1ac650068d0ed6d4c548d0462fef635fd259f17e1b3ed7117986cbd4638bcd7a07fe135decc9e4`,
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
    });
    return response.json();
}*/
