
import React from 'react';

const PageNotFound = () => {
    return (
        <div className="page-not-found">
            <div>
                <h3>Pagina No Encontrada</h3>
            </div>
        </div>
    );
};


export default PageNotFound;


