
import React, {useEffect, useState} from 'react';
import {getSuccessStories} from "../modules/actions";
import Header from "../components/header";
import { Link } from "react-router-dom";
import {Animated} from "react-animated-css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Marquee from "../components/marquee";
import {accordionSuccess, carouselSuccessStories} from "../utils/static-values";
import Accordion from "../components/accordion";
import CarouselFullHorizontalScroll from "../components/carousel-full-horizontal-scroll";
import {urls} from "../routes/routes-data";
import SEO from "../components/SEO";

const SuccessStories = () => {


    const [info, setInfo] = useState(null)
    const [showAll, setShowAll] = useState(false)
    const [renderCarousel, setRenderCarousel] = useState(true)

    const dataCarousel = carouselSuccessStories

    const getInfo = async ()=> {
        try {
            const response = await getSuccessStories()
            setInfo(response?.items)
        }catch (error) {
            console.error(error)
        }
    }

    const infoAccordion = accordionSuccess


    const showAllFunction =()=> {
        setShowAll(!showAll)
        setRenderCarousel(!renderCarousel)
        window.scrollTo(0,0)
    }


    useEffect(()=> {
        //getSuccessStories(setInfo)
        getInfo()
        window.scrollTo(0,0)
    }, [])



    return (
        <>
            <SEO
                title="HelloBrand - Casos de éxito"
                metaDescription="Hellobrand meta description hellobrand, mejora tu marca"
            />
            <Header/>

            <h1 className="title-success-main">Casos de éxito</h1>

            <h2 className="subtitle-success-main">Top team. Top results. Aquí está una muestra de lo que hemos logrado.</h2>

            {
                renderCarousel &&
                    <>
                        {
                            dataCarousel && dataCarousel.length > 0 &&
                            <CarouselFullHorizontalScroll
                                title="Así funciona HelloBrand"
                                data={dataCarousel}
                            />
                        }
                    </>
            }



            <div className={showAll ? 'success-position-button position-fixed-button' : 'success-position-button'}>
                <div  className="button" onClick={showAllFunction}>
                    {
                        showAll ?
                            'Ocultar todos los casos de éxito'
                            :
                            'Ver todos los casos de éxito'
                    }
                </div>
            </div>



            <div className={showAll ? 'show-stories-on' : 'show-stories-off'} id="stories">

                <div>
                    <div className="success-stories__grid-items">
                        { info && info.length > 0 &&

                        info.map((item, id)=> {
                            return (
                                <Link className="success-stories__grid-items__item" to={`/success-stories/${item?.fields?.canonUrl}`} key={id}>
                                    <img src={item?.fields?.mainImage?.fields?.file?.url} alt={item?.fields?.title} />

                                    <div className="success-stories__grid-items__item__info">
                                        <h2>{item?.fields?.title}</h2>
                                        <h3>{item?.fields?.typeProject}</h3>
                                    </div>
                                    <div className="success-stories__grid-items__item__animation-grid"></div>
                                    <div className="success-stories__grid-items__item__gradient-mask" />
                                </Link>

                            )
                        })
                        }
                    </div>
                </div>

            </div>


            {
                infoAccordion && infoAccordion.length > 0 &&
                <Accordion isDark={true} data={infoAccordion}/>
            }



            <Marquee
                title=" Let’s talk Let’s talk Let’s talk"
                info="Quiero una cotización"
                link={urls.workTogether}
            />





        </>
    );
};


export default SuccessStories;


