
import React, {useEffect, useState} from 'react';

import Header from "../components/header";

import CarouselDouble from "../components/carousel-double";
import { services } from "../utils/static-values";
import {Link} from "react-router-dom";
import {urls} from "../routes/routes-data";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import Marquee from "../components/marquee";
import SEO from "../components/SEO";


const Services = () => {




    const servicesData = services;

    useEffect(()=> {
        window.scrollTo(0,0)
    }, [])


    return (
        <>
            <SEO
                title="HelloBrand - Servicios"
                metaDescription="Hellobrand meta description hellobrand, mejora tu marca"
            />

           <Header/>

           <div className="services">

               <h2>Servicios</h2>

               <CarouselDouble data={servicesData} />
           </div>



            <div className="banner-black-services">
                <div className="banner-black-services__container">
                    <div>
                        <h2>Tenemos dos ideas fijas:</h2>
                        <h2>Acompañarte a hacer realidad tus sueños</h2>
                        <h2>y que tus clientes opriman muchas veces el botón comprar”</h2>
                    </div>

               {/*     <div className="banner-black-services__container__grid-content">
                        <div>
                            <p>Marketing automation</p>
                            <p>Desarrollo web</p>
                            <p>SEO</p>
                            <p>Diseño gráfico digital</p>
                            <p>Estrategia de marketing</p>
                            <p>SAC digital</p>
                            <p>Administración de canales digitales</p>
                        </div>

                        <p>Texto sobre nosotros y lo expertos que somos. Texto sobre nosotros y lo expertos que somos. Texto sobre nosotros y lo expertos que somos. Texto sobre nosotros y lo expertos que somos. Texto sobre nosotros y lo expertos que somos. Texto sobre nosotros y lo expertos que somos. Texto sobre nosotros y lo expertos que somos. Texto sobre nosotros y lo expertos que somos. </p>
                    </div>*/}
                </div>
            </div>

            <div className="services">
                <div className="services__content-button">
                    <Link to={urls.workTogether} className="button-xl">
                        Quiero una cotización
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                </div>
            </div>

            <Marquee
                title="Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works"
                info=">Inspírate con nuestro trabajo"
                link={urls.successStories}
            />

        </>
    );
};


export default Services;


