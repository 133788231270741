import React  from "react";

import LogoRed from '../assets/images/svg/logo-red.svg';
import IconArrowDown from '../assets/images/svg/icon-arrow-down.svg';
import IconArrowDownWhite from '../assets/images/svg/icon-arrow-down-white.svg';
import IconForm from '../assets/images/svg/icon-form.svg';

import CarouselHome1 from '../assets/images/img/carousel/1-investigacion.jpg';
import CarouselHome2 from '../assets/images/img/carousel/2-estrategia.jpg';
import CarouselHome3 from '../assets/images/img/carousel/3-ejecucion.jpg';

import CarouselSuccess1 from '../assets/images/img/carouselSuccessStories/1-pauta-performance.jpg';
import CarouselSuccess2 from '../assets/images/img/carouselSuccessStories/2-marketing.jpg';
import CarouselSuccess3 from '../assets/images/img/carouselSuccessStories/3-desarrollo-web.jpg';
import CarouselSuccess4 from '../assets/images/img/carouselSuccessStories/4-seo.jpg';
import CarouselSuccess5 from '../assets/images/img/carouselSuccessStories/5-diseno-grafico.jpg';
import CarouselSuccess6 from '../assets/images/img/carouselSuccessStories/6-estrategia-marketing.jpg';
import CarouselSuccess7 from '../assets/images/img/carouselSuccessStories/7-sac-digital.jpg';
import CarouselSuccess8 from '../assets/images/img/carouselSuccessStories/8-admin-digital.jpg';

import CarouselTypeSuccess1 from '../assets/images/img/carouselSuccessStories2/1.png';
import CarouselTypeSuccess2 from '../assets/images/img/carouselSuccessStories2/2.png';
import CarouselTypeSuccess3 from '../assets/images/img/carouselSuccessStories2/3.png';
import CarouselTypeSuccess4 from '../assets/images/img/carouselSuccessStories2/4.png';
import CarouselTypeSuccess5 from '../assets/images/img/carouselSuccessStories2/5.png';
import CarouselTypeSuccess6 from '../assets/images/img/carouselSuccessStories2/6.png';
import CarouselTypeSuccess7 from '../assets/images/img/carouselSuccessStories2/7.png';
import CarouselTypeSuccess8 from '../assets/images/img/carouselSuccessStories2/8.png';

import Customer1 from '../assets/images/svg/customers/01-Bain.svg';
import Customer2 from '../assets/images/svg/customers/02-GP.svg';
import Customer3 from '../assets/images/svg/customers/03-fruta-fresca.svg';
import Customer4 from '../assets/images/svg/customers/04-Lacoste.svg';
import Customer5 from '../assets/images/svg/customers/05-adidas.svg';
import Customer6 from '../assets/images/svg/customers/06-diesel.svg';
import Customer7 from '../assets/images/svg/customers/07-Gino.svg';
import Customer8 from '../assets/images/svg/customers/08-New Balance.svg';
import Customer9 from '../assets/images/svg/customers/09-Carolina-Herrera.svg';
import Customer10 from '../assets/images/svg/customers/10-tauta.svg';
import Customer11 from '../assets/images/svg/customers/11-RayBan.svg';
import Customer12 from '../assets/images/svg/customers/12-kipling.jpg';

import HeaderHover1 from '../assets/images/img/header/hover_services.jpg';
import HeaderHover2 from '../assets/images/img/header/hover_team.jpg';
import HeaderHover3 from '../assets/images/img/header/hover_success.jpg';
import HeaderHover4 from '../assets/images/img/header/hover_opinions.jpg';
import HeaderHover5 from '../assets/images/img/header/hover_blog.jpg';
import HeaderHover6 from '../assets/images/img/header/hover_worked.jpg';

import Services1 from '../assets/images/img/services/servicios1.png';

import Whatsapp from '../assets/images/svg/whatsapp.png'

import Instagram from '../assets/images/img/icons/instagram.png'
import Linkedin from '../assets/images/img/icons/linkedin.png'


export default {
    LogoRed,
    IconArrowDown,
    IconArrowDownWhite,
    CarouselHome1,
    CarouselHome2,
    CarouselHome3,
    CarouselSuccess1,
    CarouselSuccess2,
    CarouselSuccess3,
    CarouselSuccess4,
    CarouselSuccess5,
    CarouselSuccess6,
    CarouselSuccess7,
    CarouselSuccess8,
    CarouselTypeSuccess1,
    CarouselTypeSuccess2,
    CarouselTypeSuccess3,
    CarouselTypeSuccess4,
    CarouselTypeSuccess5,
    CarouselTypeSuccess6,
    CarouselTypeSuccess7,
    CarouselTypeSuccess8,
    IconForm,
    Customer1,
    Customer2,
    Customer3,
    Customer4,
    Customer5,
    Customer6,
    Customer7,
    Customer8,
    Customer9,
    Customer10,
    Customer11,
    Customer12,
    HeaderHover1,
    HeaderHover2,
    HeaderHover3,
    HeaderHover4,
    HeaderHover5,
    HeaderHover6,
    Services1,
    Whatsapp,
    Instagram,
    Linkedin
}
