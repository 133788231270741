
import React from 'react';
import { Link } from "react-router-dom";

const Marquee = ({title, info, link}) => {
    return (
        <div className="marquee-comp">
            <marquee className="marquee-comp__marquee">
                {
                    link ?
                        <div className="marquee-comp__marquee-link">
                            <Link to={link}>{title}</Link>
                        </div>
                        :
                        <>{title}</>
                }
            </marquee>
            {link ?
                <div className="marquee-comp__center-link">
                    <Link to={link}>{info}</Link>
                </div>
                :
                <h4>{info}</h4>
            }
        </div>
    );
};


export default Marquee;


