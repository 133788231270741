
import './styles/index.scss'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AnimatedCursor from "react-animated-cursor"

import Routes from './routes/index';

function App() {
  return (
      <>
        <AnimatedCursor
            innerSize={5}
            outerSize={55}
            innerScale={1}
            outerScale={2}
            outerAlpha={0}
            hasBlendMode={true}
            innerStyle={{
              backgroundColor: '#000000'
            }}
            outerStyle={{
              border: '2px solid #9e9b9b'
            }}
        />
        <Routes/>
      </>
  );
}

export default App;




