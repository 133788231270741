import React, {useState} from 'react';
import {Animated} from "react-animated-css";
import { Link } from "react-router-dom";
import img from "../utils/images";
import {urls} from "../routes/routes-data";
import HoverLinkImg from "./hover-link-img";

const Header = () => {

    const [active, setIsActive] = useState(false)
    const [activeAnimation, setIsActiveAnimation] = useState(false)
    const [animationContainer, setAnimationContainer] = useState(true)



    const activeMenu =()=> {
        if (active) {
            setTimeout(()=> {
                setAnimationContainer(false)
            }, 300)
            setTimeout(()=> {
                setIsActive(false)
            }, 1000)
            setIsActiveAnimation(false)
        } else {
            setIsActive(true)
            setAnimationContainer(true)
            setIsActiveAnimation(true)
        }

    }

    return (

        <>
            <header className="header">

                {active && <div className="close-header" onClick={activeMenu}></div>}


                <div className="left-side">
                    <Link to={urls.home} className="logo">
                        <img src={img.LogoRed} alt="Hellobrand"/>
                    </Link>
                    <div className="menu-hamb" onClick={activeMenu}>
                        <div className={active ? 'line-top active-top' : 'line-top'}/>
                        <div className={active ? 'line-down active-down' : 'line-down'}/>
                    </div>
                </div>

                <div className="right-side">
                    <a href="https://www.linkedin.com/company/hellobrandmarketing" target='_blank'>
                        <img src={img.Linkedin} alt="Hellobrand LinkedIn" />
                    </a>
                    <a href="https://www.instagram.com/hellobrandmarketing/" target='_blank'>
                        <img src={img.Instagram} alt="Hellobrand Instagram" />
                    </a>


                </div>

                {
                    active &&

                    <Animated
                        animationIn="fadeInDownBig"
                        animationOut="fadeOutUpBig"
                        animationInDuration={1000}
                        animationOutDuration={3000}
                        isVisible={animationContainer}
                        className="display-container"
                    >
                        <nav className="nav-menu">

                            <HoverLinkImg
                                to={urls.services}
                                text="Servicios"
                                imageSrc={img.HeaderHover1}
                                animationIn="slideInUp"
                                animationOut="fadeOutUpBig"
                                animationDuration={1600}
                                animationOutDuration={3000}
                                posY={1.9}
                                isActive={activeAnimation}
                            />

                         {/*   <HoverLinkImg
                                to={urls.team}
                                text="Equipo"
                                imageSrc={img.HeaderHover2}
                                animationIn="slideInUp"
                                animationOut="fadeOutUpBig"
                                animationDuration={1700}
                                animationOutDuration={3000}
                                posY={1.5}
                                isActive={activeAnimation}
                            />*/}

                            <HoverLinkImg
                                to={urls.successStories}
                                text="Casos de éxito"
                                imageSrc={img.HeaderHover3}
                                animationIn="slideInUp"
                                animationOut="fadeOutUpBig"
                                animationDuration={1800}
                                animationOutDuration={3000}
                                posY={1.2}
                                isActive={activeAnimation}
                            />

                            <HoverLinkImg
                                to={urls.opinions}
                                text="Opiniones"
                                imageSrc={img.HeaderHover4}
                                animationIn="slideInUp"
                                animationOut="fadeOutUpBig"
                                animationDuration={1900}
                                animationOutDuration={3000}
                                posY={0.99}
                                isActive={activeAnimation}
                            />

                            <HoverLinkImg
                                to={urls.blog}
                                text="Blog"
                                imageSrc={img.HeaderHover5}
                                animationIn="slideInUp"
                                animationOut="fadeOutUpBig"
                                animationDuration={1900}
                                animationOutDuration={3000}
                                posY={0.8}
                                isActive={activeAnimation}
                            />

                            <HoverLinkImg
                                to={urls.workTogether}
                                text="¡Trabajemos!"
                                imageSrc={img.HeaderHover6}
                                animationIn="slideInUp"
                                animationOut="fadeOutUpBig"
                                animationDuration={2000}
                                animationOutDuration={3000}
                                posY={0.7}
                                isActive={activeAnimation}
                            />

                        </nav>
                    </Animated>
                }
            </header>
            <div className="empty-space-header" />
        </>


    );
};


export default Header;


