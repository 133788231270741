
import React, {useEffect, useState} from 'react';
import Header from "../components/header";
import img from "../utils/images";
import {validateForm} from "../utils/validate-form";
import Marquee from "../components/marquee";
import {urls} from "../routes/routes-data";
import images from "../utils/images";
import SEO from "../components/SEO";


const WorkTogether = () => {

    const [form, setForm] = useState({
        name: '',
        email: '',
        phone: '',
        country: '',
        company: '',
        message: '',
    })

    const [error, setError] = useState('')

    const handleChange =(e)=> {
        const name = e?.target?.name
        let value = e?.target?.value

        setForm({
            ...form,
            [name]: value,
        })
    }

    const sendInfo =()=> {

        const errorsValidation = validateForm(form);
        setError(errorsValidation)

        if (Object.keys(errorsValidation).length === 0) {
            console.log("enviar")
        }
    }

    useEffect(()=> {
        window.scrollTo(0,0)
    }, [])



    return (
        <>
            <SEO
                title="HelloBrand - ¡Trabajemos!"
                metaDescription="Hacemos que tus clientes opriman muchas veces el botón comprar"
                image={images?.CarouselHome1}
            />

            <Header/>

            <div className="work-together">
                <h1>¡Trabajemos!</h1>

                <div className="work-together__grid-form">
                    <form className="work-together__grid-form__content-form">
                        <input
                            className={form?.name !== '' ? 'input-text active-input' : 'input-text'}
                            type="text"
                            value={form.name}
                            name="name"
                            placeholder="Nombre"
                            onChange={handleChange}
                        />

                        <input
                            className={form?.email !== '' ? 'input-text active-input' : 'input-text'}
                            type="text"
                            value={form.email}
                            name="email"
                            placeholder="Email"
                            onChange={handleChange}
                        />

                        <input
                            className={form?.phone !== '' ? 'input-text active-input' : 'input-text'}
                            type="text"
                            value={form.phone}
                            name="phone"
                            placeholder="Teléfono"
                            onChange={handleChange}
                        />

                        <input
                            className={form?.country !== '' ? 'input-text active-input' : 'input-text'}
                            type="text"
                            value={form.country}
                            name="country"
                            placeholder="País / Ubicación"
                            onChange={handleChange}
                        />

                        <input
                            className={form?.company !== '' ? 'input-text active-input' : 'input-text'}
                            type="text"
                            value={form.company}
                            name="company"
                            placeholder="Empresa"
                            onChange={handleChange}
                        />

                        <textarea
                            name="message"
                            className={form?.message !== '' ? 'text-area active-input' : 'text-area'}
                            placeholder="Mensaje"
                            value={form.message}
                            onChange={handleChange}
                            rows="8"
                            cols="50"
                        />

                        {
                            error &&
                                <p className="error-message">{error}</p>
                        }

                        <div className="send-form" onClick={sendInfo}>
                            Enviar
                        </div>
                    </form>
                    <div>
                        <img className="img-full-fluid" src={img.IconForm} alt="hellobrand" />
                    </div>
                </div>
            </div>


            <Marquee
                title="Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works"
                info=">Inspírate con nuestro trabajo"
                link={urls.successStories}
            />

        </>
    );
};


export default WorkTogether;


