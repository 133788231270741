
import React from 'react';
import {Helmet} from "react-helmet-async";
import {baseUrlSEO} from "../modules/env";


const SEO = ({title, metaDescription, image}) => {
    return (
        <Helmet>
            <meta charSet="utf-8" />


            <title>{title}</title>
            <meta name="description" content={metaDescription} />



            <meta property="og:title" content={title} />
            <meta name="title" key="title" content={title} />

            <meta property="og:description" content={metaDescription}/>

            <meta property="og:image" content={`${baseUrlSEO}${image}`} />

           {/* <meta property="og:image:secure_url" content={`${baseUrlSEO}${image}?format=WEBP&amp;width=720&amp;height=720&amp;scale=true&amp;is_admin=true`} />*/}
            {/*esta pagina*/}
            <meta property="og:url" content={`${baseUrlSEO}`} />
      {/*      <meta property="og:type" content="article" />*/}

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:title" content={title}/>
            <meta name="twitter:image" content={`${baseUrlSEO}${image}?format=WEBP&amp;width=720&amp;height=720&amp;scale=true&amp;is_admin=true`}/>

            {/*<meta name="twitter:site" content="@miWeb" />*/}


            {/*<meta name="viewport" content="initial-scale=1.0, width=device-width" />*/}
            {/*      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />*/}
            {/*  <meta property="og:locale" key="og:locale" content="en_US" />*/}
            {/*<meta property="og:type" key="og:type" content="website" />*/}

        </Helmet>
    );
};


export default SEO;


