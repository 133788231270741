
import React, {useEffect, useState} from 'react';
import { getTeam} from "../modules/actions";
import Header from "../components/header";
import Marquee from "../components/marquee";
import {Link} from "react-router-dom";
import {urls} from "../routes/routes-data";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import images from "../utils/images";
import SEO from "../components/SEO";


const Team = () => {

    const [info, setInfo] = useState(null)

    const getInfo = async ()=> {
        try {
            const response = await getTeam()
            setInfo(response?.items)
        }catch (error) {
            console.error(error)
        }
    }


    useEffect(()=> {
        getInfo()
        window.scrollTo(0,0)
    }, [])


    return (
        <>
            <SEO
                title="HelloBrand - Nosotros"
                metaDescription="Hacemos que tus clientes opriman muchas veces el botón comprar"
                image={images?.CarouselHome1}
            />

            <Header/>

            <div className="team">
                <div className="team__content-info">
                    <h1>Equipo</h1>
                    <div className="team__content-info__info">

                        <h2>Aquí están los expertos en estrategia, medios, creatividad, datos y tecnología que descubren los caminos y actúan para que las compañías se conecten mejor con las personas.</h2>
                    </div>

                </div>
                <div className="team__grid-team">
                    {
                        info && info.length > 0 &&
                            info.map((item, id)=> {
                                return (
                                    <div className="content-img" key={id}>
                                        <img className="img-full-fluid" src={item?.fields?.mainImage?.fields?.file?.url} alt={item?.fields?.mainImage?.title} />

                                        <div className="content-hover">
                                            <img className="img-full-fluid" src={item?.fields?.hoverImage?.fields?.file?.url} alt={item?.fields?.mainImage?.title} />
                                        </div>

                                    </div>
                                )
                            })
                    }
                </div>

                <div className="container-button">
                    <Link to={urls.workTogether} className="button-xl">
                        Quiero una cotización
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                </div>

            </div>



            <Marquee
                title="Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works"
                info=">Inspírate con nuestro trabajo"
                link={urls.successStories}
            />


        </>
    );
};


export default Team;


