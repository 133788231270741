import React, {useEffect, useState} from 'react';
import {Animated} from "react-animated-css";


const SpinnerCounter = ({}) => {

    const [exit, setExit] = useState(true)

    useEffect(()=>{
        const color=document.getElementById('background_color')
        const numero=document.getElementById('number')

        let cantidad=0
        let tiempo=setInterval(() => {
            cantidad+=1
            color.style.height=`${cantidad}%`
            numero.textContent=cantidad
            if(cantidad===100){
                clearInterval(tiempo)
                setExit(false)
                /*setTimeout(()=> {
                    setSpinner(false)
                }, 1000)*/
            }
        }, 30);
    }, [])


    return (

        <Animated
            animationIn="fadeIn"
            animationOut="fadeOutUpBig"
            animationInDuration={1000}
            animationOutDuration={3000}
            isVisible={exit}
        >

            <div className="container-counter">
                <div className="position-counter">
                    <div className="counter">
                        <div className="background_color" id="background_color" />
                        <div className="number" id="number" />
                    </div>
                </div>
            </div>


        </Animated>







    );
};


export default SpinnerCounter;


