import React from 'react';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";


gsap.registerPlugin(ScrollTrigger);

const CarouselFullHorizontalScroll = ({title, data}) => {

    const carouselHorizontalRef = useRef();
    const slider = useRef();

    useLayoutEffect(() => {
      /*  let ctx = gsap.context(() => {
            let panels = gsap.utils.toArray(".panel-full-horizontal");

            //carousel
            gsap.to(panels, {
                xPercent: -100 * (panels.length - 1),
                ease: "none",
                scrollTrigger: {
                    trigger: slider.current,
                    pin: true,
                    scrub: 1,
                    snap: 1 / (panels.length - 1),
                    end: () => "+=" + slider.current.offsetWidth
                }
            });

        }, carouselHorizontalRef);
        return () => ctx.revert();*/

        let ctx = gsap.context(() => {
            let panels = gsap.utils.toArray(".panel-full-horizontal");
            let screenWidth = window.innerWidth;
            //carousel
            if (screenWidth <= 550) {
                gsap.to(panels, {
                    xPercent: -110 * (panels.length + 1),
                    ease: "none",
                    scrollTrigger: {
                        trigger: slider.current,
                        pin: true,
                        scrub: 1,
                        snap: 1 / (panels.length - 1),
                        end: () => "+=" + slider.current.offsetWidth
                    }
                });
            } else {
                gsap.to(panels, {
                    xPercent: -120 * (panels.length - 1),
                    ease: "none",
                    scrollTrigger: {
                        trigger: slider.current,
                        pin: true,
                        scrub: 1,
                        snap: 1 / (panels.length - 1),
                        end: () => "+=" + slider.current.offsetWidth
                    }
                });
            }

        }, carouselHorizontalRef);
        return () => ctx.revert();
    });




    return (

        <div className="Apptext" ref={carouselHorizontalRef}>

            <div ref={slider} className="container-full-horizontal">

                {
                    data && data.map((item, id)=>{
                        return (
                            <div className="panel-full-horizontal" key={id}>
                                <div className="image-format">
                                    <img src={item.image}  alt="info"/>
                                    <div className="title-item-carousel">
                                        <h2>{item.title_es}</h2>
                                    </div>
                                </div>

                            </div>
                        )
                    })
                }

            </div>


        </div>

    );
};


export default CarouselFullHorizontalScroll;


