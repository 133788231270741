
import React, {useEffect, useState} from 'react';
import {getSuccessStoriesDetail, getSuccessStories} from "../modules/actions";
import Header from "../components/header";
import { Link } from "react-router-dom";
import ReactMarkdown from "react-markdown";
import {renderToStaticMarkup} from "react-dom/server";
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useHistory } from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from '@fortawesome/free-solid-svg-icons'

import { useParams } from "react-router-dom";
import PageNotFound from "../components/page-not-found";
import SEO from "../components/SEO";

const SuccessStoriesDetail = () => {

    const [info, setInfo] = useState(null)
    const [infoProjects, setInfoProjects] = useState(null)
    const [nexProject, setNexProject] = useState({})
    const [info2, setInfo2] = useState(null)
    const [errorMatch, setErrorMatch] = useState(false)

    const params = useParams()
    const history = useHistory()

/*    const getForId = async ()=> {
        try {
            const response = await getCasesId()
            console.log("la respuesta", response)
            setInfo2(response?.data)
        } catch (error) {
            console.log("error", error)
        }
    }*/

    const getDetail =async()=>{
        try {
            const response = await getSuccessStoriesDetail(params)
            setInfo(response?.items[0].fields)
        } catch (error) {
            console.error("error", error)
            setErrorMatch(true)
        }
    }

    const getProjects =async()=>{
        try {
            const response = await getSuccessStories()
            setInfoProjects(response?.items)
        } catch (error) {
            console.error("error", error)
        }
    }

    const findPosition =()=> {
        if (infoProjects && infoProjects.length > 0 && info) {
            let index = infoProjects.findIndex(x => x.fields?.canonUrl === info?.canonUrl)
            let totalProjects = infoProjects.length - 1
            let nexIndexProjects = index + 1
            if (nexIndexProjects > totalProjects) {
                setNexProject(infoProjects[0]?.fields)
            } else {
                setNexProject(infoProjects[nexIndexProjects]?.fields)
            }
        }
    }


    const  document  =  {
        nodeType : 'document' ,
        data : { } ,
        content : [
            {
                nodeType : 'paragraph' ,
                data : { } ,
                content : [
                    {
                        nodeType : 'text' ,
                        value : 'Hello world!' ,
                        marcas : [ ] ,
                        datos : { } ,
                    } ,
                ] ,
            },
        ] ,
    } ;

    const options={
        renderNode:{
            "embedded-asset-block":(node)=>{
                let file = node.data.target.fields.file;
                let jsx = renderMedia(file);
                let markup = renderToStaticMarkup(jsx);
                return markup;
            }
        }
    };


    const renderMedia=(file)=>{
        if(file.contentType==="video/mp4"){
            return (
                <div className='adjust-video'>
                    <video controls>
                        <source src={file.url} type='video/mp4'/>
                        <p>Your browser doesnt support HTML5 video.</p>
                    </video>
                </div>
            );
        }else if(file.contentType==="image/jpeg" || file.contentType==="image/png" || file.contentType==="image/jpg" || file.contentType==="image/gif" || file.contentType==="image/webp"){
            return (<img className="img-full-fluid" src={file.url} alt={file.fileName}/>);
        }
        else{
            return (<p>Unknown content type</p>);
        }
    };

    const nextProjectFunction =(value)=> {
        history.push(value)
        window.location.reload(true);
    }



    useEffect(()=> {
        getDetail()
        getProjects()
        /*        getSuccessStoriesDetail(setInfo, params, setErrorMatch)*/
        /*getCasesId(setInfo2)*/
        /*     getForId()*/
        window.scrollTo(0,0)
    }, [])


    useEffect(()=> {
        findPosition()
    }, [infoProjects, info])

    return (
        <div>
            <SEO
                title={`HelloBrand - ${info?.title}`}
                metaDescription="Hellobrand meta description hellobrand, mejora tu marca"
            />

            <Header/>

            {
                errorMatch ?
                    <PageNotFound />
                    :
                    <>
                        <>
                            <div className="success-stories-detail-top-img">
                                <img src={info?.topImage?.fields?.file?.url} alt={info?.topImage?.fields?.description} />
                                <div className="success-stories-detail-top-img__header-info">
                                    <h1>{info?.title}</h1>
                                    <h3>{info?.typeProject}</h3>
                                </div>
                            </div>
                            <div className="success-stories-detail">
                                <div className="success-stories-detail__grid-info">
                                    <p>{info?.title} - {info?.projectYear}</p>
                                    <p>{info?.valueProposal}</p>
                                </div>



                                <div className="success-stories-detail__grid-image">
                                    {
                                        info?.imagesHorizontal && info?.imagesHorizontal.length > 0 &&
                                        info?.imagesHorizontal.map((item, id)=> {
                                            return (
                                                <img src={item?.fields?.file.url} className="img-full-fluid" alt={item?.fields?.description} key={id} />
                                            )
                                        })
                                    }

                                </div>

                                <div dangerouslySetInnerHTML={{__html:documentToHtmlString(info?.infoProject, options)}} className="success-stories-detail__html-cormorant"></div>
                            </div>

                            {
                                nexProject &&
                                <div className="success-stories-detail-next-project" onClick={()=> nextProjectFunction(nexProject?.canonUrl)}>
                                    <div className="success-stories-detail-next-project__content-next">
                                        <p>Siguiente caso:</p>
                                        <div className="success-stories-detail-next-project__content-next__inline">
                                            <h3>{nexProject?.title}</h3>
                                            <FontAwesomeIcon icon={faArrowRight} />
                                        </div>
                                    </div>
                                    <div className="hover-footer-next">
                                        <img src={nexProject?.topImage?.fields?.file?.url} alt={info?.topImage?.fields?.description} />
                                    </div>
                                </div>
                            }


                        </>

                     {/*   <div dangerouslySetInnerHTML={{__html: info?.infoProject?.content[0].content[0].value}}></div>*/}
                        {/*<div dangerouslySetInnerHTML={{__html:documentToHtmlString(info?.infoProjects)}} className="grid-row20 p-a total-cormorant"></div>*/}
                    </>

            }




            {/*{
                info2 && <img className="img-full-fluid" src={`${info2?.attributes?.mainImage?.data?.attributes?.url}`} alt="cualquier"/>
            }*/}

            {/*<div>
                {
                    info2 &&
                    <ReactMarkdown>
                        {info2.attributes?.infoProject}
                    </ReactMarkdown>
                }

            </div>*/}
        </div>
    );
};


export default SuccessStoriesDetail;


