import {useEffect, useState} from "react";
import img from '../utils/images'


const Whatsapp = () => {

    const [showIcon, setShowIcon] = useState(false)


    useEffect(() => {
        setTimeout(()=> {
            setShowIcon(true)
        }, 4000)
    }, []);

    const phoneNumber = '+573108813028';

    const openWhatsApp = () => {
        // Crea el enlace de WhatsApp con el número de teléfono
        const whatsappLink = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}`;
        window.open(whatsappLink, '_blank');
    };


    return (

        <>
            {
                showIcon &&
                <div className="container-whatsapp" onClick={openWhatsApp}>
                    <img src={img.Whatsapp} alt="icon" />
                </div>
            }
        </>


    );
};


export default Whatsapp;


