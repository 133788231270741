import React from 'react';
import { hydrate, render } from "react-dom";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, Route, Switch, useHistory} from "react-router-dom";
import {urls} from "./routes/routes-data";
import Home from "./pages/home";
import Opinions from "./pages/opinions";
import Services from "./pages/services";
import WorkTogether from "./pages/work-together";
import BlogDetail from "./pages/blog-detail";
import Blog from "./pages/blog";
import Team from "./pages/team";
import SuccessStoriesDetail from "./pages/success-stories-detail";
import SuccessStories from "./pages/success-stories";
import {HelmetProvider} from "react-helmet-async";




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <HelmetProvider>
            <App />
        </HelmetProvider>
    </>
);

/*const APP =(
    <BrowserRouter history={useHistory}>
        <Switch>
            <Route exact path={urls.home} component={Home} />
            <Route exact path={urls.opinions} component={Opinions} />
            <Route exact path={urls.services} component={Services} />
            <Route exact path={urls.workTogether} component={WorkTogether} />
            <Route exact path={urls.blogDetail} component={BlogDetail} />
            <Route exact path={urls.blog} component={Blog} />
            <Route exact path={urls.team} component={Team} />
            <Route exact path={urls.successStoriesDetail} component={SuccessStoriesDetail} />
            <Route exact path={urls.successStories} component={SuccessStories} />
        </Switch>
    </BrowserRouter>
);

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(APP, rootElement);
} else {
    render(APP, rootElement);
}*/
