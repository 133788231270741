import React, { Fragment} from 'react';
import {Link} from "react-router-dom";

const TextScrollAnimation = ({text1, text2, height, linkText2}) => {



    return (

        <Fragment>
            <span className="container-text-scroll-animation" style={{height: height}}>
                <span className="action-hover-one">
                     {text1}
                </span>
                 <span className="text-two">
                     {
                         linkText2 ?
                             <Link to={linkText2}>
                                 {text2}
                             </Link>
                             :
                             <>{text2}</>
                     }

                </span>
            </span>
        </Fragment>


    );
};


export default TextScrollAnimation;


