export const formatShortDate = date => {
    if (date !== '' && date !== undefined) {
        const clearDate =  date.split('T')
        const splitDate =  clearDate[0].split('-')
        const day = splitDate[2]
        const month = monthConverted(splitDate[1])
        const year = splitDate[0]
        return `${day}  ${month} ${year}`
    }
}

export const monthConverted = month => {
    const valueMonth = parseInt(month)
    return switchCases(valueMonth, {
        [1]: 'Ene',
        [2]: 'Feb',
        [3]: 'Mar',
        [4]: 'Abr',
        [5]: 'May',
        [6]: 'Jun',
        [7]: 'Jul',
        [8]: 'Ago',
        [9]: 'Sep',
        [10]: 'Oct',
        [11]: 'Nov',
        [12]: 'Dic',
        default: '',
    })
}

//switch cases function
export function switchCases(expression, cases) {
    return cases[expression] || cases?.default
}