import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {Animated} from "react-animated-css";

const HoverLinkImg = ({ to, text, imageSrc, animationIn, animationOut, animationDuration, animationOutDuration, posY, isActive }) => {
    const [imageSize, setImageSize] = useState(100);
    const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
    const [showImage, setShowImage] = useState(false);
    const maxSize = 500;

    const handleMouseEnter = () => {
        setImageSize(maxSize);
    };

    const handleMouseLeave = () => {
        setImageSize(100);
    };

    const handleMouseMove = (e) => {
        const xPos = e.nativeEvent.clientX - imageSize / 200;
        const yPos = e.nativeEvent.clientY - imageSize / posY;
        setImagePosition({ x: xPos, y: yPos });
    };

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setShowImage(true); // Mostrar la imagen después del tiempo especificado
        }, 1000); // Cambia 1000 a la cantidad de milisegundos que desees

        return () => {
            clearTimeout(timeoutId); // Limpiar el timeout si el componente se desmonta
        };
    }, []);

    return (
        <Link
            className="nav-link"
            to={to}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onMouseMove={handleMouseMove}
        >
            <Animated
                animationIn={animationIn}
                animationOut={animationOut}
                animationInDuration={animationDuration}
                animationOutDuration={animationOutDuration}
                isVisible={true}
            >
                <div className="">
                    <div className="text-hover">
                        {text}
                        <div className="cross-effect">
                            <div className="first-line" />
                            <div className="second-line" />
                        </div>
                    </div>
                    {
                        isActive && showImage &&
                        <img
                            className="hover-image"
                            src={imageSrc}
                            alt="Hover Image"
                            style={{
                                position: 'absolute',
                                left: `${imagePosition.x}px`,
                                top: `${imagePosition.y}px`,
                                width: `${imageSize}px`,
                                transition: 'width 0.3s ease-in-out',
                                display: imageSize > 100 ? 'block' : 'none',
                                marginLeft: '-110px',
                            }}
                        />
                    }
                </div>
            </Animated>
        </Link>
    );
};

export default HoverLinkImg;