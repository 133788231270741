import React, {useEffect, useRef, useState} from 'react';

import { HashRouter, Route, Switch, useHistory } from 'react-router-dom';
import Home from '../pages/home';
import SuccessStories from '../pages/success-stories';

import SuccessStoriesDetail from "../pages/success-stories-detail";
import Team from "../pages/team"
import Blog from "../pages/blog"
import BlogDetail from "../pages/blog-detail";
import WorkTogether from "../pages/work-together";
import Services from "../pages/services";
import Opinions from "../pages/opinions";
import {urls} from "./routes-data";
import Whatsapp from "../components/whatsapp";




export default () => {

    const [spinner, setSpinner] = useState(true)
    const [showHome, setShowHome] = useState(false)


    useEffect(() => {
        setTimeout(()=> {
            setSpinner(false)
        }, 7000)
        setTimeout(()=> {
            setShowHome(true)
        }, 3000)

        const currentPath = window.location.hash;
        if (currentPath !== '#/') {
            setSpinner(false)
            setShowHome(true)
        }
    }, []);





    return (
       <>
           <HashRouter history={useHistory}>
               <Switch>
                   <Route exact path={urls.home} render={() => <Home initialSpinner={spinner} initialShowHome={showHome}/>}/>
                   <Route exact path={urls.opinions} component={Opinions} />
                   <Route exact path={urls.services} component={Services} />
                   <Route exact path={urls.workTogether} component={WorkTogether} />
                   <Route exact path={urls.blogDetail} component={BlogDetail} />
                   <Route exact path={urls.blog} component={Blog} />
                   <Route exact path={urls.team} component={Team} />
                   <Route exact path={urls.successStoriesDetail} component={SuccessStoriesDetail} />
                   <Route exact path={urls.successStories} component={SuccessStories} />
               </Switch>
           </HashRouter>
           <Whatsapp/>
       </>
    );
};

