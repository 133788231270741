import React from 'react';
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import { useLayoutEffect, useRef } from "react";


gsap.registerPlugin(ScrollTrigger);

const CarouselHorizontalScroll = ({title, data}) => {

    const carouselHorizontalRef = useRef();
    const slider = useRef();

    useLayoutEffect(() => {
        let ctx = gsap.context(() => {
            let panels = gsap.utils.toArray(".panel");
            let screenWidth = window.innerWidth;
            //carousel
            if (screenWidth <= 550) {
                gsap.to(panels, {
                    xPercent: -110 * (panels.length - 1),
                    ease: "none",
                    scrollTrigger: {
                        trigger: slider.current,
                        pin: true,
                        scrub: 1,
                        snap: 1 / (panels.length - 1),
                        end: () => "+=" + slider.current.offsetWidth
                    }
                });
            } else {
                gsap.to(panels, {
                    xPercent: -100 * (panels.length - 1),
                    ease: "none",
                    scrollTrigger: {
                        trigger: slider.current,
                        pin: true,
                        scrub: 1,
                        snap: 1 / (panels.length - 1),
                        end: () => "+=" + slider.current.offsetWidth
                    }
                });
            }


            //title animation
            const backTextWrapper = document?.querySelector(".container");
          //  document.body.style.overflow = "auto";

            gsap.utils.toArray(backTextWrapper).forEach((section, index) => {
                const textLine = section?.querySelector(".title-carousel");
                const [x, xEnd] =
                    index % 2
                        ? [window.innerWidth, (textLine.scrollWidth - section.offsetWidth) * 1]
                        : [textLine.scrollWidth * -1, 0];
                gsap.fromTo(
                    textLine,
                    { x },
                    {
                        x: xEnd,
                        scrollTrigger: {
                            trigger: section,
                            scrub: 0.5,
                        },
                    }
                );
            });

        }, carouselHorizontalRef);
        return () => ctx.revert();
    });




    return (

        <div className="Apptext" ref={carouselHorizontalRef}>
           {/* <div className="firstContainer">
                <h1>Testing horizontal scrolling w/ three sections</h1>
                <h2>First Container</h2>
            </div>*/}
            <div ref={slider} className="container">

                <div className="title-carousel">
                    {title}
                </div>


                {/*<div className="description panel blue">
                    <div>
                        SCROLL DOWN
                        <div className="scroll-down">
                            <div className="arrow"></div>
                        </div>
                    </div>
                </div>*/}

       {/*         <div className="panel red">ONE</div>
                <div className="panel orange">TWO</div>
                <div className="panel purple">THREE</div>
                <div className="panel red">ONE</div>*/}

                {
                    data && data.map((item, id)=>{
                        return (
                            <div className="panel" key={id}>
                                <div className="image-format">
                                    <img src={item?.image}  alt="info"/>
                                    <div className="title-item-carousel">
                                        <h2>{item?.title_es}</h2>
                                    </div>
                                    <div className="float-button">
                                        <div className="float-button__circle-button">
                                            {item?.button_text} <br/>
                                            >>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        )
                    })
                }



                {/*<div className="panel orange">TWO</div>
                <div className="panel purple">THREE</div>

                <div className="panel red">ONE</div>
                <div className="panel orange">TWO</div>
                <div className="panel purple">THREE</div>

                <div className="panel red">ONE</div>
                <div className="panel orange">TWO</div>
                <div className="panel purple">THREE</div>*/}

      {/*          <div className="item-carousel"></div>
                <div className="item-carousel"></div>
                <div className="item-carousel"></div>w
                <div className="item-carousel"></div>
                <div className="item-carousel"></div>
                <div className="item-carousel"></div>
                <div className="item-carousel"></div>
                <div className="item-carousel"></div>*/}
            </div>

{/*            <div className="lastContainer">Last Container</div>*/}


        </div>

    );
};


export default CarouselHorizontalScroll;


