
import React, {useEffect, useState} from 'react';
import {getBlog } from "../modules/actions";
import Header from "../components/header";
import Marquee from "../components/marquee";
import { Link } from "react-router-dom";
import {formatShortDate} from "../utils/helpers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {urls} from "../routes/routes-data";
import images from "../utils/images";
import SEO from "../components/SEO";


const Blog = () => {

    const [info, setInfo] = useState(null)
    const [relevantInfo, setRelevantInfo] = useState(null)

    const getInfo = async ()=> {
        try {
            const response = await getBlog()
            const data = response?.items
            const filterNormalInfo = data.filter(item => item?.fields?.isRelevant === false)
            const relevant = data.filter(item => item?.fields?.isRelevant === true)
            setInfo(filterNormalInfo)
            setRelevantInfo(relevant)
        }catch (error) {
            console.error(error)
        }
    }

    useEffect(()=> {
        getInfo()
        window.scrollTo(0,0)
    }, [])


    return (
        <>
            <SEO
                title="HelloBrand - Blog"
                metaDescription="Hacemos que tus clientes opriman muchas veces el botón comprar"
                image={images?.CarouselHome1}
            />
            <Header/>

            <div className="blog">

                {
                    relevantInfo && relevantInfo.length > 0 &&
                    relevantInfo.map((item, id)=> {
                        return (
                            <Link to={`/blog/${item?.fields?.canonUrl}`} className="blog__relevant-grid" key={id}>
                                <img className="img-full-fluid" src={item?.fields?.mainImage?.fields?.file?.url} alt={item?.fields?.mainImage?.fields?.description} />
                                <div className="blog__relevant-grid__right-side">
                                    <h2>{item?.fields?.title}</h2>
                                    <p>{formatShortDate(item?.fields?.date)} - {item?.fields?.category}</p>
                                </div>
                            </Link>
                        )

                    })
                }


                {
                    info && info.length > 0 &&
                    <div className="blog__grid-blog">
                        {
                            info.map((item, id)=> {
                                return (
                                    <Link to={`/blog/${item?.fields?.canonUrl}`} className="blog__grid-blog__item" key={id}>
                                        <img className="img-full-fluid" src={item?.fields?.mainImage?.fields?.file?.url} alt={item?.fields?.mainImage?.fields?.description} />
                                        <div className="blog__grid-blog__item__info">
                                            <h2>{item?.fields?.title}</h2>
                                            <p>{formatShortDate(item?.fields?.date)} - {item?.fields?.category}</p>
                                        </div>

                                    </Link>
                                )
                            })
                        }
                    </div>
                }

                <div className="blog__content-button">
                    <Link to={urls.workTogether} className="button-xl">
                        Quiero una cotización
                        <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                </div>

                <Marquee
                    title="Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works Works"
                    info=">Inspírate con nuestro trabajo"
                    link={urls.successStories}
                />

            </div>


        </>
    );
};


export default Blog;


