
import React, {useEffect, useRef, useState} from 'react';
import Slider from "react-slick";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight, faArrowLeft} from '@fortawesome/free-solid-svg-icons'
import {Animated} from "react-animated-css";

const SimpleSlider = ({data}) => {


    const [dataSlider, setDataSlider] = useState(data)
    const [stateAction, setStateAction] = useState({
        activeSlide: 0,
        activeSlideCurrent: 0
    })


    const settings = {
        dots: true,
        infinite: true,
        fade: true,
        speed: 500,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        autoplay: true,
        slidesToScroll: 1,
        swipeToSlide: true,
        beforeChange: (current, next) => setStateAction({ activeSlide: next }),
        afterChange: current => setStateAction({ activeSlideCurrent: current }),
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />

    };



    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;

        return (
            <div className="top-prev">
                <div
                    className={`${className} slick-prev-custom `}
                    style={{ ...style, display: "block"}}
                    onClick={onClick}
                >
                    <FontAwesomeIcon icon={faArrowLeft} />
                    01
                </div>
                <div className="line-prev"></div>
            </div>
        );
    }

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (

            <div className="top-prev">
                <div
                    className={`${className} slick-next-custom `}
                    style={{ ...style, display: "block"}}
                    onClick={onClick}
                >
                    {renderSliderCount(props?.slideCount)}
                    <FontAwesomeIcon icon={faArrowRight} />
                </div>
                <div className="line-next"></div>
            </div>
        );
    }

    const renderSliderCount =(count)=> {

        const value = count.toString()
        const str = value.length
        if (str > 1 ) {
            return count
        } else {
          return `0${count}`
        }

    }

    // onlyse use two slider at the same time
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);
    const slider1 = useRef(null);
    const slider2 = useRef(null);

    useEffect(() => {
        setNav1(slider1.current);
        setNav2(slider2.current);

    }, []);





    const [isMouseOverSlider, setIsMouseOverSlider] = useState(false);
    const [lastScrollDirection, setLastScrollDirection] = useState(null);
    const [timer, setTimer] = useState(null);

    const handleMouseEnter = () => {
        setIsMouseOverSlider(true);
    };

    const handleMouseLeave = () => {
        setIsMouseOverSlider(false);
    };

    const handleWheel = (event) => {
        if (isMouseOverSlider) {
            const currentScrollDirection = event.deltaY > 0 ? 'down' : 'up';

            if (currentScrollDirection !== lastScrollDirection) {
                if (currentScrollDirection === "up") {
                    handlePrevClick();
                } else {
                    handleNextClick();
                }
                setLastScrollDirection(currentScrollDirection);
            }

            clearTimeout(timer);
            setTimer(setTimeout(() => setLastScrollDirection(null), 200));
        }
    };

    const handlePrevClick = () => {
        const prevButton = document.querySelector('.slick-prev-custom');
        if (prevButton) {
            prevButton.click();
        }
    };

    const handleNextClick = () => {
        const nextButton = document.querySelector('.slick-next-custom');

        if (nextButton) {
            nextButton.click();
        }
    };


    /*useEffect(() => {
        const htmlElement = document.querySelector('html');

        console.log("stateAction", stateAction)
        console.log("data", data.length-1)

        if (window.innerWidth > 600) {

            if (stateAction.activeSlideCurrent === data.length-1){

            } else {
                htmlElement.classList.remove('hidden-scroll');
                window.removeEventListener('wheel', handleWheel);
                if (isMouseOverSlider) {
                    htmlElement.classList.add('hidden-scroll');
                    window.addEventListener('wheel', handleWheel);
                } else {
                    htmlElement.classList.remove('hidden-scroll');
                    window.removeEventListener('wheel', handleWheel);
                }
            }


        }

        return () => {
            htmlElement.classList.remove('hidden-scroll');
            window.removeEventListener('wheel', handleWheel);
        };
    }, [isMouseOverSlider, timer]);*/

    return (
        <>
            <div
                className="simple-slider"
/*                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}*/
            >

                <Slider {...settings}
                        asNavFor={nav1}
                        ref={slider2}
                >
                    {
                        dataSlider && dataSlider.map((item, id)=> {
                            let activeAction = false
                            if (item.id === stateAction.activeSlideCurrent) {
                                activeAction = true
                            }
                            return (
                               <Animated
                                   animationIn="fadeIn"
                                   animationOut="fadeOut"
                                   animationInDuration={500}
                                   animationOutDuration={1000}
                                   isVisible={activeAction}
                                   key={id}
                               >
                                   <div className="simple-slider__item" >
                                       <h3>{item?.opinion}</h3>
                                       <p>{item?.autor}</p>
                                       <p>{item?.position}</p>
                                       <p><strong>{item?.customer}</strong></p>
                                   </div>
                               </Animated>
                            )
                        })
                    }

                </Slider>
            </div>
        </>
    );
};


export default SimpleSlider;


