import img from "./images";

export const carouselHome = [
    {
        'title_es': 'Investigación',
        'name_en': 'Big contributors',
        'image': img.CarouselHome1,
        'button_text': 'Insights'
    },
    {
        'title_es': 'Estrategia y creatividad',
        'name_en': 'Micro company',
        'image': img.CarouselHome2,
        'button_text': 'Roadmap & Touchpoints'
    },
    {
        'title_es': 'Ejecución',
        'name_en': 'Small business',
        'image': img.CarouselHome3,
        'button_text': 'Optimización',
    },
    {
        'title_es': 'Investigación',
        'name_en': 'Big contributors',
        'image': img.CarouselHome1,
        'button_text': 'Insights'
    },
    {
        'title_es': 'Estrategia y creatividad',
        'name_en': 'Micro company',
        'image': img.CarouselHome2,
        'button_text': 'Roadmap & Touchpoints'
    },
    {
        'title_es': 'Ejecución',
        'name_en': 'Small business',
        'image': img.CarouselHome3,
        'button_text': 'Optimización',
    },
]

export const carouselSuccessStories = [
    {
        'title_es': "Pauta de performance \n" + "(Paid Media)",
        'name_en': 'Big contributors',
        'image': img.CarouselTypeSuccess1,
        'button_text': 'Insights'
    },
    {
        'title_es': 'AI Marketing Automation',
        'name_en': 'Micro company',
        'image': img.CarouselTypeSuccess2,
        'button_text': 'Roadmap & Touchpoints'
    },
    {
        'title_es': 'Desarrollo web',
        'name_en': 'Small business',
        'image': img.CarouselTypeSuccess3,
        'button_text': 'Optimización',
    },
    {
        'title_es': 'SEO',
        'name_en': 'Big contributors',
        'image': img.CarouselTypeSuccess4,
        'button_text': 'Insights'
    },
    {
        'title_es': 'Diseño gráfico digital',
        'name_en': 'Micro company',
        'image': img.CarouselTypeSuccess5,
        'button_text': 'Roadmap & Touchpoints'
    },
    {
        'title_es': 'Estrategia de marketing',
        'name_en': 'Small business',
        'image': img.CarouselTypeSuccess6,
        'button_text': 'Optimización',
    },
    {
        'title_es': 'SAC DIgital',
        'name_en': 'Small business',
        'image': img.CarouselTypeSuccess7,
        'button_text': 'Optimización',
    },
    {
        'title_es': 'Administración de canales digitales',
        'name_en': 'Small business',
        'image': img.CarouselTypeSuccess8,
        'button_text': 'Optimización',
    },
]


export const accordionHome = [
    {
        'id': 1,
        'title_es': 'Investigación',
        'title_en': 'Investigation',
        'paragraph': "Es el punto de partida, toda estrategia necesita datos para alimentarse es por esto que realizamos una recopilación de información sobre la marca, su competencia y el entorno  en medios digitales con preguntas claves que generan insights que son la base para crear la estrategia y creatividad.",
    },
    {
        'id': 2,
        'title_es': 'Estrategia y creatividad',
        'title_en': 'Creativity and strategy',
        'paragraph': "A partir de los insights generados en la investigación y la información histórica proporcionada por el cliente se plantea la estrategia, elementos tácticos y creativos orientados a obtener el más alto performance, estableciendo un roadmap con los puntos clave para su ejecución.",
    },
    {
        'id': 3,
        'title_es': 'Ejecución',
        'title_en': 'Execution',
        'paragraph': "con todos los insumos obtenidos iniciamos la implementación de los tácticos y el seguimiento de los resultados obtenidos en base a los KPI´s planteados y de esta forma optimizar las acciones para continuar generando el performance esperado.",
    },
]

export const accordionSuccess = [
    {
        'id': 1,
        'title_es': 'Pauta de performance (Paid Media)',
        'title_en': 'Pauta de performance (Paid Media)',
        'paragraph': "Con un objetivo fijo en conversión. Construimos estrategias full-funnel de alto impacto según la estrategia u objetivos planteados: ventas, visitantes en tu sitio web y/o leads contactables, con un objetivo claro y comunicado a la audiencia target para tu negocio. Maximizamos el ROI con estrategias que convierten, mientras alimentamos el first-party data del cliente generando CRO continuamente.",
    },
    {
        'id': 2,
        'title_es': 'AI Marketing Automation',
        'title_en': 'AI Marketing Automation',
        'paragraph': "Más allá de enviar un email personalizado, el marketing automation es un relacionamiento directo con el cliente usando e-mail, push notifications, visualización de productos dinámicos atendiendo sus preferencias particulares mediante el uso de Inteligencia Artificial, implementamos plataformas y herramientas para automatizar los procesos de marketing de tu marca, y relacionarte con tus usuarios de forma única. ",
    },
    {
        'id': 3,
        'title_es': 'Desarrollo Web',
        'title_en': 'Desarrollo Web',
        'paragraph': "Diseñamos y desarrollamos websites que hacen que tu cliente oprima muchas veces el botón comprar.  \n" +
            "Somos desarrolladores y administradores de poderosas tiendas Web que convierten el tráfico en ventas.\n" +
            "Nuestra estrategia omnicanal se adapta a los dos entornos físico y virtual.\n" +
            "\n" +
            "La clave, el diseño UX/UI:\n" +
            "HelloBrand cree en las ideas innovadoras y en la capacidad de resolver problemas basados en la investigación profunda.\n" +
            "Desarrollamos el flujo de navegación ideal para tus usuarios antes de entrar a la fase de desarrollo web, entendiendo objetivos y necesidades con datos y comportamiento para generar la mejor experiencia y empatía con tu marca, para ello usamos diferentes métodos y herramientas de investigación para desarrollar productos digitales.\n" +
            "Desarrollamos el flujo de navegación ideal para tus usuarios, entendiendo objetivos y necesidades con datos y comportamiento para generar la mejor experiencia y empatía con tu marca.\n",
    },
    {
        'id': 4,
        'title_es': 'SEO',
        'title_en': 'SEO',
        'paragraph': "Es el proceso de optimización para incrementar el tráfico orgánico a tu página web en los motores de búsqueda. \n" +
            "Creamos un plan personalizado que parte de la oferta de valor del cliente y de la revisión de los competidores en la categoría, para lograr visibilidad en los motores de búsqueda apareciendo en las primeras posiciones con backlinks y trabajo on-page.\n" +
            "Optimizamos tu web haciéndola agradable para las personas y clara para los motores de búsqueda.\n",
    },
    {
        'id': 5,
        'title_es': 'Diseño Gráfico Digital ',
        'title_en': 'Diseño Gráfico Digital ',
        'paragraph': "Creamos diseños orientados hacia los resultados. Más allá de un creativo bonito, proyectamos una comunicación clara basada en un concepto gráfico. La clave de social es un diseño práctico que a la gente le encante.",
    },
    {
        'id': 6,
        'title_es': 'Estrategia de marketing',
        'title_en': 'Estrategia de marketing',
        'paragraph': "Creamos un plan de marketing digital personalizado donde se definen y establecen los objetivos de negocio y los planes de acción para lograrlos, desde la adquisición de nuevos clientes hasta la fidelización de los mismos. \n" +
            "¡Un plan o mapa de dirección de Marketing\n" +
            "Branding: posicionamiento de marca\n" +
            "Buyer Persona: ¿Quién es el cliente?\n" +
            "Buyer Journey: ¿Cuáles son los pasos que da el cliente cuando va a comprar un producto o servicio?\n" +
            "Definición de objetivos.\n" +
            "Definición de la propuesta de valor.\n" +
            "Definición de precios de mercado.\n" +
            "Definición de canales de venta.\n" +
            "Definición de canales de tráfico.\n" +
            "Construcción del embudo de conversión.\n" +
            "Tácticas de fidelización de clientes (Acciones y tendencias).\n" +
            "El equipo de estrategia se enfoca en resolver las dudas más comunes de la dirección de marketing: ¿cuánto debería de pagar en publicidad?¿qué quieren mis clientes?¿cuánto debería de invertir en el proceso de venta?¿cómo dar una mejor experiencia a los clientes? \n" +
            "Un plan de marketing construye esa base para comprender cómo y hacia dónde dirigir: el presupuesto, los esfuerzos en diseño, UX/UI, y cómo mejorar las ventas (porque nadie paga la nómina con likes ;)...). \n",
    },
    {
        'id': 7,
        'title_es': 'SAC digital',
        'title_en': 'SAC digital',
        'paragraph': "Construimos canales que encajen eficientemente con los procesos de servicio al cliente de las compañías, para que la conectividad sea una ventaja para el servicio y no una barrera.\n" +
            "Performance email marketing.\n" +
            "Whatsapp, Telegram, otros.\n" +
            "Forms de contacto.\n" +
            "Flows automatizados.\n" +
            "Centros de clasificación de PQRS.\n" +
            "Sistemas de reviews.\n" +
            "Social listening.\n" +
            "Entre otros.\n",
    },
    {
        'id': 8,
        'title_es': 'Administración de canales digitales',
        'title_en': 'Administración de canales digitales',
        'paragraph': "El mantenimiento de una página web podría equipararse al “visual merchandising” del mundo online. \n" +
            "Contamos con el servicio de actualización y administración de e-commerce, páginas web informativas y aplicaciones móviles realizado por un equipo de programación técnico con los más altos skills que lograrán dar respuesta eficiente a cualquier necesidad.\n" +
            "Cargue de contenido (vídeos, imágenes, textos, etc.)\n" +
            "Actualización de apps/plugins\n" +
            "Actualización de plantillas\n" +
            "Adición de funcionalidades\n" +
            "Creación de back-ups\n" +
            "Aplicación de caché\n" +
            "Base de datos\n" +
            "Optimización de contenidos (videos, imágenes, textos, etc.)\n" +
            "Desarrollo de lógicas y algoritmos\n" +
            "Protección Anti spam\n" +
            "Seguridad\n" +
            "Corrección de bugs y errores (Error 404, 500, etc.)\n" +
            "Redireccionamientos\n" +
            "Entre otros.\n",
    },

]

export const accordionServices = [
    {
        'id': 1,
        'title_es': 'Pa',
        'title_en': 'AI Marketing Automation',
        'paragraph': "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
    {
        'id': 2,
        'title_es': 'Desarrollo web',
        'title_en': 'Web designer',
        'paragraph': "A partir de los insights generados en la investigación y la información histórica proporcionada por el cliente se plantea la estrategia, elementos tácticos y creativos orientados a obtener el más alto performance, estableciendo un roadmap con los puntos clave para su ejecución.",
    },
    {
        'id': 3,
        'title_es': 'SEO',
        'title_en': 'SEO',
        'paragraph': "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
    {
        'id': 4,
        'title_es': 'Diseño gráfico digital',
        'title_en': 'Diseño gráfico digital',
        'paragraph': "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
    {
        'id': 5,
        'title_es': 'Estrategia de marketing',
        'title_en': 'Estrategia de marketing',
        'paragraph': "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
    {
        'id': 6,
        'title_es': 'SAC digital',
        'title_en': 'SAC digital',
        'paragraph': "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },
    {
        'id': 7,
        'title_es': 'Administración de canales digitales',
        'title_en': 'Administración de canales digitales',
        'paragraph': "is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    },

]

export const customersLogos = [
    {
        'img': img.Customer1,
        'description': "customer"
    },
    {
        'img': img.Customer2,
        'description': "customer"
    },
    {
        'img': img.Customer3,
        'description': "customer"
    },
    {
        'img': img.Customer4,
        'description': "customer"
    },
    {
        'img': img.Customer5,
        'description': "customer"
    },
    {
        'img': img.Customer6,
        'description': "customer"
    },
    {
        'img': img.Customer7,
        'description': "customer"
    },
    {
        'img': img.Customer8,
        'description': "customer"
    },
    {
        'img': img.Customer9,
        'description': "customer"
    },
    {
        'img': img.Customer10,
        'description': "customer"
    },
    {
        'img': img.Customer11,
        'description': "customer"
    },
    {
        'img': img.Customer12,
        'description': "customer"
    },
]

export const opinionsCustomer = [
    {
        'id': 0,
        'opinion': 'HelloBrand ha sido un aliado para Gómez-Pinzón en nuestra estrategia digital y de  posicionamiento en buscadores Trabajar con Nicolás y María es trabajar con coequiperos de verdad. Entienden el negocio, se preocupan por dar valor, y hacen que las cosas pasen. No pudimos haber tomado una mejor decisión al seleccionarnos!.',
        'autor': "María Clara Alvarado",
        'position': 'Directora de Marketing y Comunicaciones',
        'customer': 'Gómez-Pinzón Abogados',
    },
    {
        'id': 1,
        'opinion': 'Con HelloBrand rompí el récord histórico de ventas de mi compañía desde que se constituyó hace más de 30 años. ',
        'autor': "Jorge A. Macías",
        'position': 'Director',
        'customer': 'Aceros Metales y Mallas.',
    },
    {
        'id': 2,
        'opinion': 'He tenido una experiencia muy positiva con HelloBrand. El equipo escucha y capta la historia de mi compañía, para que se refleje en los posts de Instagram. Me muestra la analítica para saber cuando las publicaciones son más óptimas. Mi número de seguidores ha incrementado. Hacen diseños originales y llamativos.',
        'autor': "Patty Dodd",
        'position': 'Fundadora / CEO',
        'customer': 'MBSand, California.',
    },
    {
        'id': 3,
        'opinion': 'Llevamos trabajando con algo más de 4 años y nos ha encantado esta alianza estratégica porque HelloBrand tiene un equipo siempre disponible, un equipo con la capacidad de entender lo retos que nos plantean nuestros clientes en materia de campañas digitales o narrativas digitales que queremos posicionar de cara a un reto puntual, y a su vez la disposición para trabajar en equipo con otra agencia, la verdad es que cuando nos juntamos lo que hacemos es sumar esfuerzos y entregar es el mejor producto y los mejores resultados a nuestros clientes.',
        'autor': "Laura León",
        'position': 'Socia y directora',
        'customer': 'Modum',
    },
    {
        'id': 4,
        'opinion': 'Hemos logrado construir un equipo muy cercano, la disposición y la buena energía de equipo de HelloBrand se ha visto reflejada en el manejo de nuestros clientes, en especial  con un cliente que se encuentra en Brasil y que a pesar de la barrera del idioma hemos logrado construir una relación cercana y es por la personalidad, disposición y buena energía que hemos construido en equipo.',
        'autor': "Marcela Martínez",
        'position': 'Fundadora / CEO',
        'customer': 'Bain Account Manager',
    },
]


export const services = [
    {
        'id': 0,
        'title': "Pauta de performance \n" +
            "(Paid Media)",
        'description': "Con un objetivo fijo en conversión. Construimos estrategias full-funnel de alto impacto según la estrategia u objetivos planteados: ventas, visitantes en tu sitio web y/o leads contactables, con un objetivo claro y comunicado a la audiencia target para tu negocio. Maximizamos el ROI con estrategias que convierten, mientras alimentamos el first-party data del cliente generando CRO continuamente.",
        'image': img.CarouselSuccess1,
    },
    {
        'id': 1,
        'title': 'AI Marketing Automation',
        'description': "Más allá de enviar un email personalizado, el marketing automation es un relacionamiento directo con el cliente usando e-mail, push notifications, visualización de productos dinámicos atendiendo sus preferencias particulares mediante el uso de Inteligencia Artificial, implementamos plataformas y herramientas para automatizar los procesos de marketing de tu marca, y relacionarte con tus usuarios de forma única. ",
        'image': img.CarouselSuccess2,
    },
    {
        'id': 2,
        'title': 'Desarrollo Web',
        'description': "Diseñamos y desarrollamos websites que hacen que tu cliente oprima muchas veces el botón comprar.  \n" +
            "Somos desarrolladores y administradores de poderosas tiendas Web que convierten el tráfico en ventas.\n" +
            "Nuestra estrategia omnicanal se adapta a los dos entornos físico y virtual.\n" +
            "\n" +
            "La clave, el diseño UX/UI:\n" +
            "HelloBrand cree en las ideas innovadoras y en la capacidad de resolver problemas basados en la investigación profunda.\n" +
            "Desarrollamos el flujo de navegación ideal para tus usuarios antes de entrar a la fase de desarrollo web, entendiendo objetivos y necesidades con datos y comportamiento para generar la mejor experiencia y empatía con tu marca, para ello usamos diferentes métodos y herramientas de investigación para desarrollar productos digitales.\n" +
            "Desarrollamos el flujo de navegación ideal para tus usuarios, entendiendo objetivos y necesidades con datos y comportamiento para generar la mejor experiencia y empatía con tu marca.\n",
        'image': img.CarouselSuccess3,
    },
    {
        'id': 3,
        'title': 'SEO',
        'description': "Es el proceso de optimización para incrementar el tráfico orgánico a tu página web en los motores de búsqueda. \n" +
            "Creamos un plan personalizado que parte de la oferta de valor del cliente y de la revisión de los competidores en la categoría, para lograr visibilidad en los motores de búsqueda apareciendo en las primeras posiciones con backlinks y trabajo on-page.\n" +
            "Optimizamos tu web haciéndola agradable para las personas y clara para los motores de búsqueda.\n",
        'image': img.CarouselSuccess4,
    },
    {
        'id': 4,
        'title': 'Diseño gráfico digital',
        'description': "Creamos diseños orientados hacia los resultados. Más allá de un creativo bonito, proyectamos una comunicación clara basada en un concepto gráfico. La clave de social es un diseño práctico que a la gente le encante.",
        'image': img.CarouselSuccess5,
    },
    {
        'id': 5,
        'title': 'Estrategia de marketing ',
        'description': "Creamos un plan de marketing digital personalizado donde se definen y establecen los objetivos de negocio y los planes de acción para lograrlos, desde la adquisición de nuevos clientes hasta la fidelización de los mismos. \n" +
            "¡Un plan o mapa de dirección de Marketing\n" +
            "Branding: posicionamiento de marca\n" +
            "Buyer Persona: ¿Quién es el cliente?\n" +
            "Buyer Journey: ¿Cuáles son los pasos que da el cliente cuando va a comprar un producto o servicio?\n" +
            "Definición de objetivos.\n" +
            "Definición de la propuesta de valor.\n" +
            "Definición de precios de mercado.\n" +
            "Definición de canales de venta.\n" +
            "Definición de canales de tráfico.\n" +
            "Construcción del embudo de conversión.\n" +
            "Tácticas de fidelización de clientes (Acciones y tendencias).\n" +
            "El equipo de estrategia se enfoca en resolver las dudas más comunes de la dirección de marketing: ¿cuánto debería de pagar en publicidad?¿qué quieren mis clientes?¿cuánto debería de invertir en el proceso de venta?¿cómo dar una mejor experiencia a los clientes? \n" +
            "Un plan de marketing construye esa base para comprender cómo y hacia dónde dirigir: el presupuesto, los esfuerzos en diseño, UX/UI, y cómo mejorar las ventas (porque nadie paga la nómina con likes ;)...). \n",
        'image': img.CarouselSuccess6,
    },
    {
        'id': 6,
        'title': 'SAC digital',
        'description': "Construimos canales que encajen eficientemente con los procesos de servicio al cliente de las compañías, para que la conectividad sea una ventaja para el servicio y no una barrera.\n" +
            "Performance email marketing.\n" +
            "Whatsapp, Telegram, otros.\n" +
            "Forms de contacto.\n" +
            "Flows automatizados.\n" +
            "Centros de clasificación de PQRS.\n" +
            "Sistemas de reviews.\n" +
            "Social listening.\n" +
            "Entre otros.\n",
        'image': img.CarouselSuccess7,
    },
    {
        'id': 7,
        'title': 'Administración de canales digitales',
        'description': "El mantenimiento de una página web podría equipararse al “visual merchandising” del mundo online. \n" +
            "Contamos con el servicio de actualización y administración de e-commerce, páginas web informativas y aplicaciones móviles realizado por un equipo de programación técnico con los más altos skills que lograrán dar respuesta eficiente a cualquier necesidad.\n" +
            "Cargue de contenido (vídeos, imágenes, textos, etc.)\n" +
            "Actualización de apps/plugins\n" +
            "Actualización de plantillas\n" +
            "Adición de funcionalidades\n" +
            "Creación de back-ups\n" +
            "Aplicación de caché\n" +
            "Base de datos\n" +
            "Optimización de contenidos (videos, imágenes, textos, etc.)\n" +
            "Desarrollo de lógicas y algoritmos\n" +
            "Protección Anti spam\n" +
            "Seguridad\n" +
            "Corrección de bugs y errores (Error 404, 500, etc.)\n" +
            "Redireccionamientos\n" +
            "Entre otros.\n",
        'image': img.CarouselSuccess8,
    },
]


