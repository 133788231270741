const regularExpEspecialCharacters = /^[A-zÀ-úñÑ0-9\s\p{Letter}\:,;/%.()_-]+$/;

export function validateForm(values) {
    let errors = '';

    if (!values.name) {
        errors = "El nombre es obligatorio*";
    } else if (!regularExpEspecialCharacters.test(values.name)) {
        errors = "El nombre no es valido*";
    }

    if (!values.email) {
        errors = "El email es obligatorio*";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors = "Correo no válido";
    }

    if (!values.phone) {
        errors = "";
    } else if (values.phone.length > 10) {
        errors = "Número inválido";
    }

    if (!values.country) {
        errors = "El país es obligatorio*";
    }

    if (!values.company) {
        errors = "La compañia es obligatoria*";
    }

    if (!values.message) {
        errors = "El mensaje es obligario*";
    }


    return errors;
}