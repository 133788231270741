export const urls = {
    home: "/",
    successStories: "/success-stories",
    successStoriesDetail: "/success-stories/:id",
    team: "/team",
    blog: "/blog",
    blogDetail: "/blog/:id",
    workTogether: "/work-together",
    services: "/services",
    opinions: "/opinions"
}